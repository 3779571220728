/* //fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500&display=swap')");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;700;800;900&display=swap");

@font-face {
  font-family: "typo_round";
  src: url("../Font/typo_round_bold_demo-webfont.woff2") format("woff2"),
    url("../Font/typo_round_bold_demo-webfont.woff") format("woff");
}

:root {
  --font-Montserrat: "Montserrat", sans-serif;
  --fancy-font: "Poppins", sans-serif;
}

body {
  font-family: var(--font-Montserrat), system-ui, -apple-system,
    /* Firefox supports this but not yet `system-ui` */
    "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.font-serif {
  font-family: var(--font-Montserrat);
}

.font-popins {
  font-family: "Poppins", sans-serif;
}

.font-typo {
  font-family: "typo_round";
}

/* ========================================================= */
/* my commin bundle */
.blue {
  color: #1881bb;
}

.custom-border {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

li {
  background-image: none !important;
}

/* font family */
.underline {
  text-decoration: underline;
  line-height: 30px;
}

.text-capital {
  text-transform: capitalize;
}

.font-mont {
  font-family: var(--font-Montserrat);
}

.fancy-font {
  font-family: var(--fancy-font);
}

/* colors (text && bg) */
.text-osperb-blue {
  color: #1881bb;
}

.bg-osperb {
  background: #1881bb;
  color: #fff;
}

.bg-myGray {
  background-color: #f5f5f7;
}

.bg-whitesmoke-op {
  /* background-color: whitesmoke; */
  background-color: #0a0a0a;
}

.bg-whitesmoke {
  background-color: whitesmoke;
}

/* border-radius */
.border-r-30 {
  border-radius: 30px;
}

.w-fit {
  width: fit-content !important;
}

/*// layout //*/

/* height */
.height-80 {
  height: 80px;
}

.h-half-screen {
  height: 50vh;
}

.h-3\/4-screen {
  height: 75vh;
}

.h-2\.5\/4-screen {
  height: 62vh;
}

.min-h-half-screen {
  min-height: 50vh;
}

.plr-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* width */
/* flex*/
.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.w-15 {
  width: 15%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

.w-50 {
  width: 50%;
}

.navBarUl {
  transition: all ease-in-out 0.6s;
}

.ulShow {
  transform: translateY(0rem);
}

.ulHidden {
  transform: translateY(-5rem);
}

/* other styles */
.cursor-pointer {
  cursor: pointer !important;
}

.textarea-height textarea {
  height: 100px;
}

.blend {
  mix-blend-mode: difference;
}

/* display */
.d-none-bigger {
  display: none;
}

.d-none-smaller {
  display: block;
}

.object-cover {
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .d-none-bigger {
    display: block;
  }

  .d-none-smaller {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .sm\:p-0 {
    padding: 0rem !important;
  }

  .sm\:m-0 {
    margin: 0 !important;
  }

  .sm\:p-10 {
    padding: 1.5rem;
  }

  .sm\:w-9 {
    width: 90%;
  }

  .sm\:w-none {
    display: none;
  }

  .sm\:bg-glass {
    background: rgba(255, 255, 255, 0.45);
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    /* backdrop-filter: blur(4px); */
    /* -webkit-backdrop-filter: blur(4px); */
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    margin-left: inherit;
  }

  .ulHidden {
    transform: translateY(-20rem);
  }
}

/* ========================================================= */
/*///////// custome css ///////////*/

.MSI_show_element {
  display: none;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.gallery-item a {
  height: 100%;
}

.bottom_card.move {
  bottom: -100px;
}

.bottom_card {
  position: absolute;
  width: 100%;
  /* background: #d8d8d8; */
  padding: 15px;
  /* transform: translateY(100px); */
  transition: all ease-in-out 0.3s;
  background: rgb(251 251 253 / 25%);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.gallery-item:hover .bottom_card {
  /* transform: translateY(0px); */
  bottom: 0px;
}

.custom-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.box_sm {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 300px;
  height: 300px;
  background-color: #f2f2f2;
  transform: rotateZ(90deg);
  margin: 0 1rem;
}

.box_sm.hidden_bg {
  background: #0a0a0a;
}

.box_sm img {
  transform: rotateZ(-90deg);
}

.gray-hover {
  filter: grayscale(1);
  transition: all ease-in-out 0.3s;
}

.gray-hover:hover {
  filter: grayscale(0);
}

.bg-landing-background {
  background-image: linear-gradient(rgba(255, 255, 255, 0.11), rgba(0, 0, 0, 2)),
    url(../img/osperb/illustration/team.svg);
}

.underline-hover {
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  position: relative;
  line-height: 27px;
}

.underline-hover:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  background: #cd7c3c;
  transition: width 0.3s ease-in-out 0s, left 0.3s ease-in-out 0s;
  width: 0;
}

.underline-hover:hover:after {
  width: 100%;
}

.underline-hover.full:after {
  width: 100%;
}

.sp-underline-blue {
  /* border-bottom: 4px solid #1881bb; */
  line-height: 35px;
  position: relative;
}

.sp-underline-blue:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  background: #1881bb;
  transition: width 0.3s ease-in-out 0s, left 0.3s ease-in-out 0s;
  width: 100%;
}

.sp-underline-blue:hover:after {
  width: 200%;
}

.navHide {
  transform: translateY(-90px);
}

.navShow {
  transform: translateY(0);
}

.nav-span span {
  height: 100%;
}

.fixed_menu {
  font-weight: 700;
  position: fixed;
  width: 100vh;
  text-align: center;
  mix-blend-mode: difference;
  /* left: 0; */
  z-index: 2;
}

.fixed_menu h1 {
  color: white;
}

.video-container {
  width: 100%;
  margin-top: -2rem;
  height: 60%;
  transition: all ease-in-out 02s;
  margin-left: -70px;
}

.video-tag {
  width: 100%;
  box-shadow: 20px 20px 60px 0px #d9d9d9a6, -20px -20px 20px #ffffff;
  border-radius: 1.3rem;
}

.video-container:hover {
  transform: translate(-10px, -10px);
}

.video-tag2 {
  width: 100%;
  border-radius: 1.3rem;
}

.footer_logo {
  margin-left: -25px;
}

.show-card-anim-bf {
  transform: translate(0, -10rem);
  opacity: 0;
  visibility: hidden;
}

.show-card-anim-af {
  transform: translate(0, 0rem);
  opacity: 1;
  visibility: visible;
}

.isoTopContainer {
  /* margin: auto; */
  /* width: 80%; */
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.img-400 {
  height: 100% !important;
  object-fit: cover;
}

.isoTopContainer::-webkit-scrollbar {
  display: none;
}

.custome-scroll {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
  background-color: #fbfbfd;
}

.custome-scroll-div {
  width: 500px;
  height: 500px;
  position: relative;
  background-color: #f2f2f2;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
}

.label_card {
  position: absolute;
  bottom: -20rem;
  border-radius: 0;
  box-shadow: none;
  transition: all 0.45s ease-in-out;
  background: rgb(251 251 253 / 25%);
  backdrop-filter: blur(20px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.bottom-label {
  position: absolute;
  bottom: -0;
  border-radius: 0;
  box-shadow: none;
  transition: all 0.45s ease-in-out;
  background: rgb(251 251 253 / 25%);
  backdrop-filter: blur(20px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
}

.react__arrowPrev {
  bottom: 0;
  position: absolute;
  font-size: 2.5rem;
  right: 50px;
  width: fit-content;
  cursor: pointer;
}

.react__arrowNext {
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
  font-size: 2.5rem;
}

.custome-scroll-div:hover .label_card {
  bottom: 0rem;
}

.view_more_card {
  width: 250px;
  height: 500px;
}

.custome_scroll_bar {
  overflow-x: scroll;
}

.slick-slide {
  padding: 0.5rem;
}

.about-px-4 {
  padding-right: 4rem;
}

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
}

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

body {
  margin: 0;
}

body {
  font-family: system-ui, -apple-system,
    /* Firefox supports this but not yet `system-ui` */
    "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

hr {
  height: 0;
  /* 1 */
  color: inherit;
  /* 2 */
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo,
    monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

html {
  font-family: Roboto, sans-serif;
  /* 1 */
  line-height: 1.5;
  /* 2 */
}

body {
  font-family: inherit;
  line-height: inherit;
}

*,
::before,
::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: currentColor;
  /* 2 */
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button,
[role="button"] {
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

pre,
code,
kbd,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* 1 */
  vertical-align: middle;
  /* 2 */
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*,
::before,
::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-blur: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-brightness: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-contrast: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-grayscale: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-hue-rotate: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-invert: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-saturate: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-sepia: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-drop-shadow: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.top-20 {
  top: 5rem;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.z-10 {
  z-index: 10;
}

.m-0 {
  margin: 0px;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0px;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.-ml-3 {
  margin-left: -0.75rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-screen {
  height: 100vh;
}

.h-10 {
  height: 2.5rem;
}

.h-auto {
  height: auto;
}

.h-12 {
  height: 3rem;
}

.h-2 {
  height: 0.5rem;
}

.h-28 {
  height: 7rem;
}

.h-16 {
  height: 4rem;
}

.w-full {
  width: 100%;
}

.w-3\/12 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-60 {
  width: 15rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.min-w-0 {
  min-width: 0px;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-max {
  max-width: -webkit-max-content;
  max-width: max-content;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.flex-auto {
  flex: 1 1 auto;
}

.transform {
  -webkit-transform: var(--tw-transform);
  transform: var(--tw-transform);
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-full {
  border-radius: 9999px;
  overflow: hidden;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.bg-landing-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url(https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80);
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.object-cover {
  object-fit: cover;
}

.p-20 {
  padding: 5rem;
}

.p-4 {
  padding: 1rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-0 {
  padding: 0px;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-serif {
  font-family: "Roboto Slab", serif;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.underline {
  text-decoration: underline;
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.filter {
  -webkit-filter: var(--tw-filter);
  filter: var(--tw-filter);
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.tippy-box[data-placement^="top"]>.tippy-svg-arrow {
  bottom: 0;
}

.tippy-box[data-placement^="top"]>.tippy-svg-arrow:after,
.tippy-box[data-placement^="top"]>.tippy-svg-arrow>svg {
  top: 16px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.tippy-box[data-placement^="bottom"]>.tippy-svg-arrow {
  top: 0;
}

.tippy-box[data-placement^="bottom"]>.tippy-svg-arrow>svg {
  bottom: 16px;
}

.tippy-box[data-placement^="left"]>.tippy-svg-arrow {
  right: 0;
}

.tippy-box[data-placement^="left"]>.tippy-svg-arrow:after,
.tippy-box[data-placement^="left"]>.tippy-svg-arrow>svg {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: calc(50% - 3px);
  left: 11px;
}

.tippy-box[data-placement^="right"]>.tippy-svg-arrow {
  left: 0;
}

.tippy-box[data-placement^="right"]>.tippy-svg-arrow:after,
.tippy-box[data-placement^="right"]>.tippy-svg-arrow>svg {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: calc(50% - 3px);
  right: 11px;
}

.tippy-svg-arrow {
  width: 16px;
  height: 16px;
  fill: #333;
  text-align: initial;
}

.tippy-svg-arrow,
.tippy-svg-arrow>svg {
  position: absolute;
}

.tippy-box[data-animation="scale"][data-placement^="top"] {
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}

.tippy-box[data-animation="scale"][data-placement^="bottom"] {
  -webkit-transform-origin: top;
  transform-origin: top;
}

.tippy-box[data-animation="scale"][data-placement^="left"] {
  -webkit-transform-origin: right;
  transform-origin: right;
}

.tippy-box[data-animation="scale"][data-placement^="right"] {
  -webkit-transform-origin: left;
  transform-origin: left;
}

.tippy-box[data-animation="scale"][data-state="hidden"] {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0;
}

.tippy-box[data-animation="shift-away"][data-state="hidden"] {
  opacity: 0;
}

.tippy-box[data-animation="shift-away"][data-state="hidden"][data-placement^="top"] {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.tippy-box[data-animation="shift-away"][data-state="hidden"][data-placement^="bottom"] {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

.tippy-box[data-animation="shift-away"][data-state="hidden"][data-placement^="left"] {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}

.tippy-box[data-animation="shift-away"][data-state="hidden"][data-placement^="right"] {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  color: #bdbdbd;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #bdbdbd;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #bdbdbd;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

* {
  --tw-shadow: 0 0 #0000;
  --tw-ring-inset: var(--tw-empty,
      /*!*/
      /*!*/
    );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(33, 150, 243, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
}

.fixed {
  position: fixed;
}

.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.right-4 {
  right: 1rem;
}

.top-1\/3 {
  top: 33.333333%;
}

.left-0 {
  left: 0px;
}

.right-0 {
  right: 0px;
}

.top-16 {
  top: 4rem;
}

.-top-1\.5 {
  top: -0.375rem;
}

.-top-0\.5 {
  top: -0.125rem;
}

.-top-1 {
  top: -0.25rem;
}

.-top-0 {
  top: 0px;
}

.top-1\/4 {
  top: 25%;
}

.top-1\/2 {
  top: 50%;
}

.right-2 {
  right: 0.5rem;
}

.right-3 {
  right: 0.75rem;
}

.top-2 {
  top: 0.5rem;
}

.top-0 {
  top: 0px;
}

.bottom-20 {
  bottom: 20px;
}

.bottom-0 {
  bottom: 0px;
}

.-bottom-4 {
  bottom: -1rem;
}

.-bottom-5 {
  bottom: -1.25rem;
}

.z-30 {
  z-index: 30;
}

.z-20 {
  z-index: 20;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-40 {
  z-index: 40;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-9 {
  margin-top: -2.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-3 {
  margin-top: 0.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-0 {
  margin-top: 0px;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-16 {
  height: 4rem;
}

.h-7 {
  height: 1.75rem;
}

.h-10 {
  height: 2.5rem;
}

.h-32 {
  height: 8rem;
}

.h-20 {
  height: 5rem;
}

.h-12 {
  height: 3rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-5 {
  height: 1.25rem;
}

.h-screen {
  height: 100vh;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-9 {
  height: 2.25rem;
}

.h-11 {
  height: 2.75rem;
}

.h-4 {
  height: 1rem;
}

.h-px {
  height: 1px;
}

.h-2 {
  height: 0.5rem;
}

.w-full {
  width: 100%;
}

.w-40 {
  width: 10rem;
}

.w-72 {
  width: 18rem;
}

.w-\[28rem\] {
  width: 28rem;
}

.w-64 {
  width: 16rem;
}

.w-96 {
  width: 24rem;
}

.w-48 {
  width: 12rem;
}

.w-16 {
  width: 4rem;
}

.w-7 {
  width: 1.75rem;
}

.w-32 {
  width: 8rem;
}

.w-12 {
  width: 3rem;
}

.w-10 {
  width: 2.5rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-5 {
  width: 1.25rem;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-4 {
  width: 1rem;
}

.w-auto {
  width: auto;
}

.w-14 {
  width: 3.5rem;
}

.min-w-0 {
  min-width: 0px;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-max {
  max-width: -webkit-max-content;
  max-width: max-content;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-none {
  flex: none;
}

.flex-grow {
  flex-grow: 1;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/3 {
  --tw-translate-y: -33.333333%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}

.translate-y-0 {
  --tw-translate-y: 0px;
}

.-translate-y-10 {
  --tw-translate-y: -2.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.list-none {
  list-style-type: none;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-center {
  place-items: center;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.items-start {
  align-items: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.gap-8 {
  gap: 2rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-x-2 {
  -webkit-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-x-24 {
  -webkit-column-gap: 6rem;
  column-gap: 6rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-x-8 {
  -webkit-column-gap: 2rem;
  column-gap: 2rem;
}

.space-y-4> :not([hidden])~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-r {
  border-right-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-blue-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 239, 241, var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 238, 238, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.border-blue-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity));
}

.border-brown-500 {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity));
}

.border-deep-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity));
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity));
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity));
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity));
}

.border-light-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity));
}

.border-teal-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity));
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity));
}

.border-light-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity));
}

.border-deep-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
}

.border-transparent {
  border-color: transparent;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

.bg-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 81, 0, var(--tw-bg-opacity));
}

.bg-light-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 33, 33, var(--tw-bg-opacity));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 216, 53, var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 47, 47, var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity));
}

.bg-blue-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 71, 79, var(--tw-bg-opacity));
}

.bg-blue-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity));
}

.bg-brown-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity));
}

.bg-deep-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity));
}

.bg-light-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity));
}

.bg-deep-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: transparent;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity));
}

.bg-blue-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity));
}

.bg-brown-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity));
}

.bg-deep-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 188, var(--tw-bg-opacity));
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 178, var(--tw-bg-opacity));
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 179, var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 249, 196, var(--tw-bg-opacity));
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 244, 195, var(--tw-bg-opacity));
}

.bg-light-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 237, 200, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 230, 201, var(--tw-bg-opacity));
}

.bg-teal-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 223, 219, var(--tw-bg-opacity));
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 235, 242, var(--tw-bg-opacity));
}

.bg-light-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 229, 252, var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 222, 251, var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 202, 233, var(--tw-bg-opacity));
}

.bg-deep-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity));
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 190, 231, var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 187, 208, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 205, 210, var(--tw-bg-opacity));
}

.bg-blue-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 50, 56, var(--tw-bg-opacity));
}

.bg-brown-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(62, 39, 35, var(--tw-bg-opacity));
}

.bg-deep-orange-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 54, 12, var(--tw-bg-opacity));
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 111, 0, var(--tw-bg-opacity));
}

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 127, 23, var(--tw-bg-opacity));
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 119, 23, var(--tw-bg-opacity));
}

.bg-light-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 105, 30, var(--tw-bg-opacity));
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(27, 94, 32, var(--tw-bg-opacity));
}

.bg-teal-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 77, 64, var(--tw-bg-opacity));
}

.bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 96, 100, var(--tw-bg-opacity));
}

.bg-light-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(1, 87, 155, var(--tw-bg-opacity));
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 71, 161, var(--tw-bg-opacity));
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(26, 35, 126, var(--tw-bg-opacity));
}

.bg-deep-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 27, 146, var(--tw-bg-opacity));
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 20, 140, var(--tw-bg-opacity));
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(136, 14, 79, var(--tw-bg-opacity));
}

.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(183, 28, 28, var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity));
}

.bg-blue-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(176, 190, 197, var(--tw-bg-opacity));
}

.bg-brown-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(188, 170, 164, var(--tw-bg-opacity));
}

.bg-deep-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 171, 145, var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 128, var(--tw-bg-opacity));
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 130, var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 245, 157, var(--tw-bg-opacity));
}

.bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 238, 156, var(--tw-bg-opacity));
}

.bg-light-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 225, 165, var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 214, 167, var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 203, 196, var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(128, 222, 234, var(--tw-bg-opacity));
}

.bg-light-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 212, 250, var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(144, 202, 249, var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(159, 168, 218, var(--tw-bg-opacity));
}

.bg-deep-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 157, 219, var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(206, 147, 216, var(--tw-bg-opacity));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 143, 177, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 154, 154, var(--tw-bg-opacity));
}

.bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}

.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.from-blue-gray-500 {
  --tw-gradient-from: #607d8b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(96, 125, 139, 0));
}

.from-gray-500 {
  --tw-gradient-from: #9e9e9e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(158, 158, 158, 0));
}

.from-brown-500 {
  --tw-gradient-from: #795548;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(121, 85, 72, 0));
}

.from-deep-orange-500 {
  --tw-gradient-from: #ff5722;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 87, 34, 0));
}

.from-orange-500 {
  --tw-gradient-from: #ff9800;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 152, 0, 0));
}

.from-amber-500 {
  --tw-gradient-from: #ffc107;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 193, 7, 0));
}

.from-yellow-600 {
  --tw-gradient-from: #fdd835;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 216, 53, 0));
}

.from-lime-500 {
  --tw-gradient-from: #cddc39;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(205, 220, 57, 0));
}

.from-light-green-500 {
  --tw-gradient-from: #8bc34a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(139, 195, 74, 0));
}

.from-green-500 {
  --tw-gradient-from: #4caf50;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(76, 175, 80, 0));
}

.from-teal-500 {
  --tw-gradient-from: #009688;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 150, 136, 0));
}

.from-cyan-500 {
  --tw-gradient-from: #00bcd4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 188, 212, 0));
}

.from-light-blue-500 {
  --tw-gradient-from: #03a9f4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(3, 169, 244, 0));
}

.from-blue-500 {
  --tw-gradient-from: #2196f3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(33, 150, 243, 0));
}

.from-indigo-500 {
  --tw-gradient-from: #3f51b5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(63, 81, 181, 0));
}

.from-deep-purple-500 {
  --tw-gradient-from: #673ab7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(103, 58, 183, 0));
}

.from-purple-500 {
  --tw-gradient-from: #9c27b0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(156, 39, 176, 0));
}

.from-pink-500 {
  --tw-gradient-from: #e91e63;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(233, 30, 99, 0));
}

.from-red-500 {
  --tw-gradient-from: #f44336;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(244, 67, 54, 0));
}

.from-light-blue-700 {
  --tw-gradient-from: #0288d1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(2, 136, 209, 0));
}

.to-blue-gray-700 {
  --tw-gradient-to: #455a64;
}

.to-gray-700 {
  --tw-gradient-to: #616161;
}

.to-brown-700 {
  --tw-gradient-to: #5d4037;
}

.to-deep-orange-700 {
  --tw-gradient-to: #e64a19;
}

.to-orange-700 {
  --tw-gradient-to: #f57c00;
}

.to-amber-700 {
  --tw-gradient-to: #ffa000;
}

.to-yellow-800 {
  --tw-gradient-to: #f9a825;
}

.to-lime-700 {
  --tw-gradient-to: #afb42b;
}

.to-light-green-700 {
  --tw-gradient-to: #689f38;
}

.to-green-700 {
  --tw-gradient-to: #388e3c;
}

.to-teal-700 {
  --tw-gradient-to: #00796b;
}

.to-cyan-700 {
  --tw-gradient-to: #0097a7;
}

.to-light-blue-700 {
  --tw-gradient-to: #0288d1;
}

.to-blue-700 {
  --tw-gradient-to: #1976d2;
}

.to-indigo-700 {
  --tw-gradient-to: #303f9f;
}

.to-deep-purple-700 {
  --tw-gradient-to: #512da8;
}

.to-purple-700 {
  --tw-gradient-to: #7b1fa2;
}

.to-pink-700 {
  --tw-gradient-to: #c2185b;
}

.to-red-700 {
  --tw-gradient-to: #d32f2f;
}

.to-light-blue-500 {
  --tw-gradient-to: #03a9f4;
}

.p-4 {
  padding: 1rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-0 {
  padding: 0px;
}

.p-2\.5 {
  padding: 0.625rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pl-0 {
  padding-left: 0px;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-1\.5 {
  padding-top: 0.375rem;
}

.pb-0\.5 {
  padding-bottom: 0.125rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pb-0 {
  padding-bottom: 0px;
}

.pt-2\.5 {
  padding-top: 0.625rem;
}

.pb-1\.5 {
  padding-bottom: 0.375rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pt-3\.5 {
  padding-top: 0.875rem;
}

.pb-2\.5 {
  padding-bottom: 0.625rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.align-middle {
  vertical-align: middle;
}

.font-serif {
  font-family: "Roboto Slab", serif;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xsm {
  font-size: 0.7rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-snug {
  line-height: 1.375;
}

.tracking-wider {
  letter-spacing: 0.05em;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity));
}

.text-blue-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity));
}

.text-light-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity));
}

.text-blue-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-text-opacity));
}

.text-blue-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(38, 50, 56, var(--tw-text-opacity));
}

.text-blue-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(176, 190, 197, var(--tw-text-opacity));
}

.text-blue-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 239, 241, var(--tw-text-opacity));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgba(0, 172, 193, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(30, 136, 229, var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(66, 165, 245, var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(236, 64, 122, var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(229, 57, 53, var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(117, 117, 117, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-blue-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity));
}

.text-brown-500 {
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity));
}

.text-deep-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(253, 216, 53, var(--tw-text-opacity));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity));
}

.text-light-green-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity));
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity));
}

.text-deep-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(189, 189, 189, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity));
}

.text-brown-700 {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity));
}

.text-deep-orange-700 {
  --tw-text-opacity: 1;
  color: rgba(230, 74, 25, var(--tw-text-opacity));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgba(245, 124, 0, var(--tw-text-opacity));
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(251, 192, 45, var(--tw-text-opacity));
}

.text-lime-700 {
  --tw-text-opacity: 1;
  color: rgba(175, 180, 43, var(--tw-text-opacity));
}

.text-light-green-700 {
  --tw-text-opacity: 1;
  color: rgba(104, 159, 56, var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-text-opacity));
}

.text-teal-700 {
  --tw-text-opacity: 1;
  color: rgba(0, 121, 107, var(--tw-text-opacity));
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgba(0, 151, 167, var(--tw-text-opacity));
}

.text-light-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(2, 136, 209, var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(25, 118, 210, var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(48, 63, 159, var(--tw-text-opacity));
}

.text-deep-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(81, 45, 168, var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(123, 31, 162, var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(194, 24, 91, var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(238, 238, 238, var(--tw-text-opacity));
}

.text-brown-800 {
  --tw-text-opacity: 1;
  color: rgba(78, 52, 46, var(--tw-text-opacity));
}

.text-deep-orange-800 {
  --tw-text-opacity: 1;
  color: rgba(216, 67, 21, var(--tw-text-opacity));
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgba(239, 108, 0, var(--tw-text-opacity));
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgba(255, 143, 0, var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 37, var(--tw-text-opacity));
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgba(158, 157, 36, var(--tw-text-opacity));
}

.text-light-green-800 {
  --tw-text-opacity: 1;
  color: rgba(85, 139, 47, var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(46, 125, 50, var(--tw-text-opacity));
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgba(0, 105, 92, var(--tw-text-opacity));
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgba(0, 131, 143, var(--tw-text-opacity));
}

.text-light-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(2, 119, 189, var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(21, 101, 192, var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(40, 53, 147, var(--tw-text-opacity));
}

.text-deep-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(69, 39, 160, var(--tw-text-opacity));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(106, 27, 154, var(--tw-text-opacity));
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(173, 20, 87, var(--tw-text-opacity));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(198, 40, 40, var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-opacity-60 {
  --tw-text-opacity: 0.6;
}

.no-underline {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder-white:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.placeholder-white::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}

.placeholder-opacity-50:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}

.placeholder-opacity-50::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.5;
}

.placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: 0.5;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-5 {
  opacity: 0.05;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-blue-gray {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(96, 125, 139, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-gray {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(158, 158, 158, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-brown {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(121, 85, 72, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-deep-orange {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(255, 87, 36, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-orange {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(255, 152, 0, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-amber {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(255, 193, 7, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-yellow {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(255, 235, 59, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-lime {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(205, 220, 57, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-light-green {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(139, 195, 74, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-green {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(76, 175, 80, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-teal {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 150, 136, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-cyan {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 188, 212, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-light-blue {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(3, 169, 244, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-blue {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(33, 150, 243, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-indigo {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(63, 81, 181, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-deep-purple {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(103, 58, 183, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-purple {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(156, 39, 176, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-pink {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(233, 30, 99, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md-red {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(244, 67, 54, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-blue-gray {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(69, 90, 100, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-gray {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(97, 97, 97, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-brown {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(93, 64, 55, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-deep-orange {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(230, 74, 25, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-orange {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(245, 122, 0, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-amber {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(255, 160, 0, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-yellow {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(251, 192, 45, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-lime {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(175, 180, 43, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-light-green {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(104, 159, 56, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-green {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(56, 142, 60, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-teal {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(0, 121, 107, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-cyan {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(0, 151, 167, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-light-blue {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(2, 136, 209, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-blue {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(25, 118, 210, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-indigo {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(48, 63, 159, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-deep-purple {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(81, 45, 168, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-purple {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(123, 31, 162, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-pink {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(194, 24, 91, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg-red {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(211, 47, 47, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration {
  transition-duration: 150ms;
}

.duration-500 {
  transition-duration: 500ms;
}

/* osperb osperb Input */
.mt-input+label:after {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 0.125rem;
  left: 0px;
  height: 100%;
  width: 100%;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 0;
  border-width: 1px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
}

.mt-input:focus+label:after {
  --tw-scale-x: 1;
}

.mt-input:not(:-ms-input-placeholder)+label span {
  top: -14%;
}

.mt-input:focus+label span,
.mt-input:not(:placeholder-shown)+label span {
  top: -14%;
}

.mt-input:not(:-ms-input-placeholder)+label span {
  font-size: 0.75rem;
  line-height: 1rem;
  line-height: 1.25;
}

.mt-input:focus+label span,
.mt-input:not(:placeholder-shown)+label span {
  font-size: 0.75rem;
  line-height: 1rem;
  line-height: 1.25;
}

.texterea:not(:-ms-input-placeholder)+label span {
  top: -22px !important;
}

.texterea:focus+label span,
.texterea:not(:placeholder-shown)+label span {
  top: -22px !important;
}

/* osperb osperb Input Outline */
.mt-input-outline:not(:-ms-input-placeholder) {
  border-top: transparent !important;
}

.mt-input-outline:focus,
.mt-input-outline:not(:placeholder-shown) {
  border-top: transparent !important;
}

.mt-input-outline+label {
  line-height: 3.625 !important;
}

.mt-input-outline:not(:-ms-input-placeholder)+label {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  line-height: 1.25 !important;
}

.mt-input-outline:focus+label,
.mt-input-outline:not(:placeholder-shown)+label {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  line-height: 1.25 !important;
}

.mt-input-outline+label:before,
.mt-input-outline+label:after {
  content: "";
  pointer-events: none;
  margin-top: 0.375rem;
  box-sizing: border-box;
  display: block;
  height: 0.5rem;
  width: 0.625rem;
  border-width: 1px;
  border-top-width: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-color: transparent;
}

.mt-input-outline:not(:-ms-input-placeholder)+label:before,
.mt-input-outline:not(:-ms-input-placeholder)+label:after {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.mt-input-outline:not(:placeholder-shown)+label:before,
.mt-input-outline:not(:placeholder-shown)+label:after {
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(224, 224, 224, var(--tw-border-opacity));
}

.mt-input-outline:focus+label::before,
.mt-input-outline:focus+label::after {
  border-top-width: 2px;
}

.mt-input-outline+label:before {
  margin-right: 0.25rem;
  border-top-left-radius: 0.5rem;
}

.mt-input-outline+label:after {
  margin-left: 0.25rem;
  flex-grow: 1;
  border-top-right-radius: 0.5rem;
}

/* osperb osperb Radio Button */
.mt-radio:checked+label span {
  box-shadow: 0px 0px 0px 2px white inset;
  --tw-bg-opacity: 1 !important;
}

.mt-radio:checked+label {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity));
}

.mt-radio+label span::before {
  content: "";
  top: -1.5px;
  left: -1.5px;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  position: absolute;
  z-index: 10;
  margin: 0px;
  display: block;
  height: 1rem;
  width: 1rem;
  border-radius: 9999px;
  opacity: 0;
}

.mt-radio:checked+label span::before {
  -webkit-animation: radio-ripple 0.3s linear;
  animation: radio-ripple 0.3s linear;
}

/* osperb osperb Checkbox */
.mt-checkbox:checked+label span {
  --tw-bg-opacity: 1 !important;
}

.mt-checkbox:checked+label {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity));
}

.mt-checkbox+label span::before {
  content: "";
  top: -1px;
  left: -1.5px;
  -webkit-transform: scale3d(2, 2, 1);
  transform: scale3d(2, 2, 1);
  position: absolute;
  z-index: 10;
  margin: 0px;
  display: block;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 9999px;
  opacity: 0;
}

.mt-checkbox+label span::after {
  content: "";
  top: 1px;
  left: 6px;
  position: absolute;
  height: 0.875rem;
  width: 0.375rem;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-rotate: 45deg;
  border-bottom-width: 2px;
  border-right-width: 2px;
  border-color: transparent;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 200ms;
}

.mt-checkbox:checked+label span::before {
  -webkit-animation: radio-ripple 0.3s linear;
  animation: radio-ripple 0.3s linear;
}

.mt-checkbox:checked+label span::after {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

/* osperb osperb Input Colors */
.mt-input-blue-gray-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity));
}

.mt-input-gray-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity));
}

.mt-input-orange-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity));
}

.mt-input-deep-orange-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity));
}

.mt-input-amber-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity));
}

.mt-input-yellow-600:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity));
}

.mt-input-lime-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity));
}

.mt-input-light-green-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity));
}

.mt-input-green-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity));
}

.mt-input-teal-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity));
}

.mt-input-cyan-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity));
}

.mt-input-light-blue-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity));
}

.mt-input-blue-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity));
}

.mt-input-indigo-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity));
}

.mt-input-purple-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity));
}

.mt-input-deep-purple-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity));
}

.mt-input-pink-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity));
}

.mt-input-red-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
}

/* osperb osperb Input Outline Colors */
.mt-input-outline-blue-gray-500:focus,
.mt-input-outline-blue-gray-500:focus+label,
.mt-input-outline-blue-gray-500:focus+label:before,
.mt-input-outline-blue-gray-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(96, 125, 139, var(--tw-text-opacity));
}

.mt-input-outline-gray-500:focus,
.mt-input-outline-gray-500:focus+label,
.mt-input-outline-gray-500:focus+label:before,
.mt-input-outline-gray-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(158, 158, 158, var(--tw-text-opacity));
}

.mt-input-outline-orange-500:focus,
.mt-input-outline-orange-500:focus+label,
.mt-input-outline-orange-500:focus+label:before,
.mt-input-outline-orange-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 152, 0, var(--tw-text-opacity));
}

.mt-input-outline-deep-orange-500:focus,
.mt-input-outline-deep-orange-500:focus+label,
.mt-input-outline-deep-orange-500:focus+label:before,
.mt-input-outline-deep-orange-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 87, 34, var(--tw-text-opacity));
}

.mt-input-outline-brown-500:focus,
.mt-input-outline-brown-500:focus+label,
.mt-input-outline-brown-500:focus+label:before,
.mt-input-outline-brown-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(121, 85, 72, var(--tw-text-opacity));
}

.mt-input-outline-amber-500:focus,
.mt-input-outline-amber-500:focus+label,
.mt-input-outline-amber-500:focus+label:before,
.mt-input-outline-amber-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 193, 7, var(--tw-text-opacity));
}

.mt-input-outline-yellow-600:focus,
.mt-input-outline-yellow-600:focus+label,
.mt-input-outline-yellow-600:focus+label:before,
.mt-input-outline-yellow-600:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(253, 216, 53, var(--tw-text-opacity));
}

.mt-input-outline-lime-500:focus,
.mt-input-outline-lime-500:focus+label,
.mt-input-outline-lime-500:focus+label:before,
.mt-input-outline-lime-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(205, 220, 57, var(--tw-text-opacity));
}

.mt-input-outline-light-green-500:focus,
.mt-input-outline-light-green-500:focus+label,
.mt-input-outline-light-green-500:focus+label:before,
.mt-input-outline-light-green-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(139, 195, 74, var(--tw-text-opacity));
}

.mt-input-outline-green-500:focus,
.mt-input-outline-green-500:focus+label,
.mt-input-outline-green-500:focus+label:before,
.mt-input-outline-green-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity));
}

.mt-input-outline-teal-500:focus,
.mt-input-outline-teal-500:focus+label,
.mt-input-outline-teal-500:focus+label:before,
.mt-input-outline-teal-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 150, 136, var(--tw-text-opacity));
}

.mt-input-outline-cyan-500:focus,
.mt-input-outline-cyan-500:focus+label,
.mt-input-outline-cyan-500:focus+label:before,
.mt-input-outline-cyan-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 188, 212, var(--tw-text-opacity));
}

.mt-input-outline-light-blue-500:focus,
.mt-input-outline-light-blue-500:focus+label,
.mt-input-outline-light-blue-500:focus+label:before,
.mt-input-outline-light-blue-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(3, 169, 244, var(--tw-text-opacity));
}

.mt-input-outline-blue-500:focus,
.mt-input-outline-blue-500:focus+label,
.mt-input-outline-blue-500:focus+label:before,
.mt-input-outline-blue-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(33, 150, 243, var(--tw-text-opacity));
}

.mt-input-outline-indigo-500:focus,
.mt-input-outline-indigo-500:focus+label,
.mt-input-outline-indigo-500:focus+label:before,
.mt-input-outline-indigo-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(63, 81, 181, var(--tw-text-opacity));
}

.mt-input-outline-purple-500:focus,
.mt-input-outline-purple-500:focus+label,
.mt-input-outline-purple-500:focus+label:before,
.mt-input-outline-purple-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(156, 39, 176, var(--tw-text-opacity));
}

.mt-input-outline-deep-purple-500:focus,
.mt-input-outline-deep-purple-500:focus+label,
.mt-input-outline-deep-purple-500:focus+label:before,
.mt-input-outline-deep-purple-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(103, 58, 183, var(--tw-text-opacity));
}

.mt-input-outline-pink-500:focus,
.mt-input-outline-pink-500:focus+label,
.mt-input-outline-pink-500:focus+label:before,
.mt-input-outline-pink-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(233, 30, 99, var(--tw-text-opacity));
}

.mt-input-outline-red-500:focus,
.mt-input-outline-red-500:focus+label,
.mt-input-outline-red-500:focus+label:before,
.mt-input-outline-red-500:focus+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity));
}

.mt-input-outline-error:not(:-ms-input-placeholder)+label,
.mt-input-outline-error:not(:-ms-input-placeholder)+label:before,
.mt-input-outline-error:not(:-ms-input-placeholder)+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity));
}

.mt-input-outline-error:not(:placeholder-shown)+label,
.mt-input-outline-error:not(:placeholder-shown)+label:before,
.mt-input-outline-error:not(:placeholder-shown)+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(244, 67, 54, var(--tw-text-opacity));
}

.mt-input-outline-success:not(:-ms-input-placeholder)+label,
.mt-input-outline-success:not(:-ms-input-placeholder)+label:before,
.mt-input-outline-success:not(:-ms-input-placeholder)+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity));
}

.mt-input-outline-success:not(:placeholder-shown)+label,
.mt-input-outline-success:not(:placeholder-shown)+label:before,
.mt-input-outline-success:not(:placeholder-shown)+label:after {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(76, 175, 80, var(--tw-text-opacity));
}

/* osperb osperb Radio Button and Checkbox Colors */
.mt-radio-blue-gray-500:checked+label span,
.mt-radio-blue-gray-500+label span::before,
.mt-checkbox-blue-gray-500:checked+label span,
.mt-checkbox-blue-gray-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-gray-500:checked+label span,
.mt-radio-gray-500+label span::before,
.mt-checkbox-gray-500:checked+label span,
.mt-checkbox-gray-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-orange-500:checked+label span,
.mt-radio-orange-500+label span::before,
.mt-checkbox-orange-500:checked+label span,
.mt-checkbox-orange-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-deep-orange-500:checked+label span,
.mt-radio-deep-orange-500+label span::before,
.mt-checkbox-deep-orange-500:checked+label span,
.mt-checkbox-deep-orange-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-brown-500:checked+label span,
.mt-radio-brown-500+label span::before,
.mt-checkbox-brown-500:checked+label span,
.mt-checkbox-brown-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-amber-500:checked+label span,
.mt-radio-amber-500+label span::before,
.mt-checkbox-amber-500:checked+label span,
.mt-checkbox-amber-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-yellow-600:checked+label span,
.mt-radio-yellow-600+label span::before,
.mt-checkbox-yellow-600:checked+label span,
.mt-checkbox-yellow-600+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(253, 216, 53, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-lime-500:checked+label span,
.mt-radio-lime-500+label span::before,
.mt-checkbox-lime-500:checked+label span,
.mt-checkbox-lime-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-light-green-500:checked+label span,
.mt-radio-light-green-500+label span::before,
.mt-checkbox-light-green-500:checked+label span,
.mt-checkbox-light-green-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-green-500:checked+label span,
.mt-radio-green-500+label span::before,
.mt-checkbox-green-500:checked+label span,
.mt-checkbox-green-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-teal-500:checked+label span,
.mt-radio-teal-500+label span::before,
.mt-checkbox-teal-500:checked+label span,
.mt-checkbox-teal-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-cyan-500:checked+label span,
.mt-radio-cyan-500+label span::before,
.mt-checkbox-cyan-500:checked+label span,
.mt-checkbox-cyan-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-light-blue-500:checked+label span,
.mt-radio-light-blue-500+label span::before,
.mt-checkbox-light-blue-500:checked+label span,
.mt-checkbox-light-blue-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-blue-500:checked+label span,
.mt-radio-blue-500+label span::before,
.mt-checkbox-blue-500:checked+label span,
.mt-checkbox-blue-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-indigo-500:checked+label span,
.mt-radio-indigo-500+label span::before,
.mt-checkbox-indigo-500:checked+label span,
.mt-checkbox-indigo-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-purple-500:checked+label span,
.mt-radio-purple-500+label span::before,
.mt-checkbox-purple-500:checked+label span,
.mt-checkbox-purple-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-deep-purple-500:checked+label span,
.mt-radio-deep-purple-500+label span::before,
.mt-checkbox-deep-purple-500:checked+label span,
.mt-checkbox-deep-purple-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-pink-500:checked+label span,
.mt-radio-pink-500+label span::before,
.mt-checkbox-pink-500:checked+label span,
.mt-checkbox-pink-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

.mt-radio-red-500:checked+label span,
.mt-radio-red-500+label span::before,
.mt-checkbox-red-500:checked+label span,
.mt-checkbox-red-500+label span::before {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.2;
}

/* Tippy Boxx */
.tippy-box:focus {
  outline: none;
}

/* Tippy Animation */
.tippy-box[data-animation="scale"] {
  -webkit-transform-origin: top left !important;
  transform-origin: top left !important;
}

/* Tippy Arrow */
.arrow-light .tippy-svg-arrow {
  fill: #fff;
}

.arrow-dark .tippy-svg-arrow {
  fill: rgba(0, 0, 0, 0.8);
}

/* osperb osperb Radio Button Ripple Effect Animation */
@-webkit-keyframes radio-ripple {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale3d(2, 2, 1);
    transform: scale3d(2, 2, 1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(3, 3, 1);
    transform: scale3d(3, 3, 1);
  }
}

@keyframes radio-ripple {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale3d(2, 2, 1);
    transform: scale3d(2, 2, 1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(3, 3, 1);
    transform: scale3d(3, 3, 1);
  }
}

.last\:mr-0:last-child {
  margin-right: 0px;
}

.hover\:border-blue-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(69, 90, 100, var(--tw-border-opacity));
}

.hover\:border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(97, 97, 97, var(--tw-border-opacity));
}

.hover\:border-brown-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(93, 64, 55, var(--tw-border-opacity));
}

.hover\:border-deep-orange-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(230, 74, 25, var(--tw-border-opacity));
}

.hover\:border-orange-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 124, 0, var(--tw-border-opacity));
}

.hover\:border-amber-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 160, 0, var(--tw-border-opacity));
}

.hover\:border-yellow-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 192, 45, var(--tw-border-opacity));
}

.hover\:border-lime-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(175, 180, 43, var(--tw-border-opacity));
}

.hover\:border-light-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(104, 159, 56, var(--tw-border-opacity));
}

.hover\:border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(56, 142, 60, var(--tw-border-opacity));
}

.hover\:border-teal-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 121, 107, var(--tw-border-opacity));
}

.hover\:border-cyan-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 151, 167, var(--tw-border-opacity));
}

.hover\:border-light-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(2, 136, 209, var(--tw-border-opacity));
}

.hover\:border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(25, 118, 210, var(--tw-border-opacity));
}

.hover\:border-indigo-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(48, 63, 159, var(--tw-border-opacity));
}

.hover\:border-deep-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(81, 45, 168, var(--tw-border-opacity));
}

.hover\:border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(123, 31, 162, var(--tw-border-opacity));
}

.hover\:border-pink-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(194, 24, 91, var(--tw-border-opacity));
}

.hover\:border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(211, 47, 47, var(--tw-border-opacity));
}

.hover\:bg-blue-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 90, 100, var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(97, 97, 97, var(--tw-bg-opacity));
}

.hover\:bg-brown-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(93, 64, 55, var(--tw-bg-opacity));
}

.hover\:bg-deep-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(230, 74, 25, var(--tw-bg-opacity));
}

.hover\:bg-orange-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 124, 0, var(--tw-bg-opacity));
}

.hover\:bg-amber-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 160, 0, var(--tw-bg-opacity));
}

.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 192, 45, var(--tw-bg-opacity));
}

.hover\:bg-lime-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 180, 43, var(--tw-bg-opacity));
}

.hover\:bg-light-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(104, 159, 56, var(--tw-bg-opacity));
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 142, 60, var(--tw-bg-opacity));
}

.hover\:bg-teal-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 121, 107, var(--tw-bg-opacity));
}

.hover\:bg-cyan-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 151, 167, var(--tw-bg-opacity));
}

.hover\:bg-light-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 136, 209, var(--tw-bg-opacity));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(25, 118, 210, var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(48, 63, 159, var(--tw-bg-opacity));
}

.hover\:bg-deep-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(81, 45, 168, var(--tw-bg-opacity));
}

.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(123, 31, 162, var(--tw-bg-opacity));
}

.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(194, 24, 91, var(--tw-bg-opacity));
}

.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(211, 47, 47, var(--tw-bg-opacity));
}

.hover\:bg-blue-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 239, 241, var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
}

.hover\:bg-brown-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 235, 233, var(--tw-bg-opacity));
}

.hover\:bg-deep-orange-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 233, 231, var(--tw-bg-opacity));
}

.hover\:bg-orange-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 243, 224, var(--tw-bg-opacity));
}

.hover\:bg-amber-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 248, 225, var(--tw-bg-opacity));
}

.hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 253, 231, var(--tw-bg-opacity));
}

.hover\:bg-lime-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 251, 231, var(--tw-bg-opacity));
}

.hover\:bg-light-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 248, 233, var(--tw-bg-opacity));
}

.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 245, 233, var(--tw-bg-opacity));
}

.hover\:bg-teal-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 242, 241, var(--tw-bg-opacity));
}

.hover\:bg-cyan-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 247, 250, var(--tw-bg-opacity));
}

.hover\:bg-light-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 245, 254, var(--tw-bg-opacity));
}

.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(227, 242, 253, var(--tw-bg-opacity));
}

.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 234, 246, var(--tw-bg-opacity));
}

.hover\:bg-deep-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 231, 246, var(--tw-bg-opacity));
}

.hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 229, 245, var(--tw-bg-opacity));
}

.hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 228, 236, var(--tw-bg-opacity));
}

.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 238, var(--tw-bg-opacity));
}

.hover\:bg-blue-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 125, 139, var(--tw-bg-opacity));
}

.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity));
}

.hover\:bg-brown-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(121, 85, 72, var(--tw-bg-opacity));
}

.hover\:bg-deep-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 87, 34, var(--tw-bg-opacity));
}

.hover\:bg-orange-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 152, 0, var(--tw-bg-opacity));
}

.hover\:bg-amber-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 193, 7, var(--tw-bg-opacity));
}

.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 216, 53, var(--tw-bg-opacity));
}

.hover\:bg-lime-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(205, 220, 57, var(--tw-bg-opacity));
}

.hover\:bg-light-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 195, 74, var(--tw-bg-opacity));
}

.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 175, 80, var(--tw-bg-opacity));
}

.hover\:bg-teal-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 150, 136, var(--tw-bg-opacity));
}

.hover\:bg-cyan-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 188, 212, var(--tw-bg-opacity));
}

.hover\:bg-light-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(3, 169, 244, var(--tw-bg-opacity));
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(33, 150, 243, var(--tw-bg-opacity));
}

.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 81, 181, var(--tw-bg-opacity));
}

.hover\:bg-deep-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 58, 183, var(--tw-bg-opacity));
}

.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 39, 176, var(--tw-bg-opacity));
}

.hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 30, 99, var(--tw-bg-opacity));
}

.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 67, 54, var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(33, 33, 33, var(--tw-text-opacity));
}

.hover\:text-blue-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 71, 79, var(--tw-text-opacity));
}

.hover\:text-blue-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(69, 90, 100, var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(97, 97, 97, var(--tw-text-opacity));
}

.hover\:text-brown-700:hover {
  --tw-text-opacity: 1;
  color: rgba(93, 64, 55, var(--tw-text-opacity));
}

.hover\:text-deep-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgba(230, 74, 25, var(--tw-text-opacity));
}

.hover\:text-orange-700:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 124, 0, var(--tw-text-opacity));
}

.hover\:text-amber-700:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 160, 0, var(--tw-text-opacity));
}

.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 192, 45, var(--tw-text-opacity));
}

.hover\:text-lime-700:hover {
  --tw-text-opacity: 1;
  color: rgba(175, 180, 43, var(--tw-text-opacity));
}

.hover\:text-light-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(104, 159, 56, var(--tw-text-opacity));
}

.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(56, 142, 60, var(--tw-text-opacity));
}

.hover\:text-teal-700:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 121, 107, var(--tw-text-opacity));
}

.hover\:text-cyan-700:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 151, 167, var(--tw-text-opacity));
}

.hover\:text-light-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 136, 209, var(--tw-text-opacity));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(25, 118, 210, var(--tw-text-opacity));
}

.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgba(48, 63, 159, var(--tw-text-opacity));
}

.hover\:text-deep-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(81, 45, 168, var(--tw-text-opacity));
}

.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(123, 31, 162, var(--tw-text-opacity));
}

.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgba(194, 24, 91, var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(211, 47, 47, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:shadow-lg-blue-gray:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(69, 90, 100, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-gray:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(97, 97, 97, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-brown:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(93, 64, 55, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-deep-orange:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(230, 74, 25, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-orange:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(245, 122, 0, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-amber:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(255, 160, 0, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-yellow:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(251, 192, 45, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-lime:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(175, 180, 43, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-light-green:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(104, 159, 56, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-green:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(56, 142, 60, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-teal:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(0, 121, 107, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-cyan:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(0, 151, 167, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-light-blue:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(2, 136, 209, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-blue:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(25, 118, 210, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-indigo:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(48, 63, 159, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-deep-purple:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(81, 45, 168, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-purple:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(123, 31, 162, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-pink:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(194, 24, 91, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg-red:hover {
  --tw-shadow: 0 12px 22px -5px rgba(0, 0, 0, 0.12),
    0 10px 10px -5px rgba(0, 0, 0, 0.04),
    0 13px 24px -11px rgba(211, 47, 47, 0.6);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-blue-gray:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(96, 125, 139, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-gray:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(158, 158, 158, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-brown:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(121, 85, 72, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-deep-orange:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(255, 87, 36, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-orange:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(255, 152, 0, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-amber:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(255, 193, 7, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-yellow:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(255, 235, 59, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-lime:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(205, 220, 57, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-light-green:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(139, 195, 74, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-green:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(76, 175, 80, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-teal:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 150, 136, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-cyan:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 188, 212, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-light-blue:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(3, 169, 244, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-blue:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(33, 150, 243, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-indigo:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(63, 81, 181, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-deep-purple:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(103, 58, 183, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-purple:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(156, 39, 176, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-pink:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(233, 30, 99, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md-red:hover {
  --tw-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.1),
    0 4px 5px -2px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(244, 67, 54, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:border-2:focus {
  border-width: 2px;
}

.focus\:border-blue-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(96, 125, 139, var(--tw-border-opacity));
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(158, 158, 158, var(--tw-border-opacity));
}

.focus\:border-brown-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(121, 85, 72, var(--tw-border-opacity));
}

.focus\:border-deep-orange-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 87, 34, var(--tw-border-opacity));
}

.focus\:border-orange-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 152, 0, var(--tw-border-opacity));
}

.focus\:border-amber-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 193, 7, var(--tw-border-opacity));
}

.focus\:border-yellow-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 216, 53, var(--tw-border-opacity));
}

.focus\:border-lime-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(205, 220, 57, var(--tw-border-opacity));
}

.focus\:border-light-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(139, 195, 74, var(--tw-border-opacity));
}

.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(76, 175, 80, var(--tw-border-opacity));
}

.focus\:border-teal-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 150, 136, var(--tw-border-opacity));
}

.focus\:border-cyan-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 188, 212, var(--tw-border-opacity));
}

.focus\:border-light-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(3, 169, 244, var(--tw-border-opacity));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(33, 150, 243, var(--tw-border-opacity));
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(63, 81, 181, var(--tw-border-opacity));
}

.focus\:border-deep-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(103, 58, 183, var(--tw-border-opacity));
}

.focus\:border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(156, 39, 176, var(--tw-border-opacity));
}

.focus\:border-pink-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(233, 30, 99, var(--tw-border-opacity));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 67, 54, var(--tw-border-opacity));
}

.focus\:bg-blue-gray-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 144, 156, var(--tw-bg-opacity));
}

.focus\:bg-gray-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(189, 189, 189, var(--tw-bg-opacity));
}

.focus\:bg-brown-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(141, 110, 99, var(--tw-bg-opacity));
}

.focus\:bg-deep-orange-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 112, 67, var(--tw-bg-opacity));
}

.focus\:bg-orange-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 167, 38, var(--tw-bg-opacity));
}

.focus\:bg-amber-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 202, 40, var(--tw-bg-opacity));
}

.focus\:bg-yellow-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 235, 59, var(--tw-bg-opacity));
}

.focus\:bg-lime-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 225, 87, var(--tw-bg-opacity));
}

.focus\:bg-light-green-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 204, 101, var(--tw-bg-opacity));
}

.focus\:bg-green-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(102, 187, 106, var(--tw-bg-opacity));
}

.focus\:bg-teal-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 166, 154, var(--tw-bg-opacity));
}

.focus\:bg-cyan-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(38, 198, 218, var(--tw-bg-opacity));
}

.focus\:bg-light-blue-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(41, 182, 246, var(--tw-bg-opacity));
}

.focus\:bg-blue-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 165, 245, var(--tw-bg-opacity));
}

.focus\:bg-indigo-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(92, 107, 192, var(--tw-bg-opacity));
}

.focus\:bg-deep-purple-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(126, 87, 194, var(--tw-bg-opacity));
}

.focus\:bg-purple-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(171, 71, 188, var(--tw-bg-opacity));
}

.focus\:bg-pink-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 64, 122, var(--tw-bg-opacity));
}

.focus\:bg-red-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 83, 80, var(--tw-bg-opacity));
}

.focus\:bg-gray-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity));
}

.focus\:bg-opacity-20:focus {
  --tw-bg-opacity: 0.2;
}

.focus\:text-gray-800:focus {
  --tw-text-opacity: 1;
  color: rgba(66, 66, 66, var(--tw-text-opacity));
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}

.active\:bg-blue-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 71, 79, var(--tw-bg-opacity));
}

.active\:bg-gray-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(66, 66, 66, var(--tw-bg-opacity));
}

.active\:bg-brown-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(78, 52, 46, var(--tw-bg-opacity));
}

.active\:bg-deep-orange-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(216, 67, 21, var(--tw-bg-opacity));
}

.active\:bg-orange-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 108, 0, var(--tw-bg-opacity));
}

.active\:bg-amber-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 143, 0, var(--tw-bg-opacity));
}

.active\:bg-yellow-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 37, var(--tw-bg-opacity));
}

.active\:bg-lime-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 157, 36, var(--tw-bg-opacity));
}

.active\:bg-light-green-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 139, 47, var(--tw-bg-opacity));
}

.active\:bg-green-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(46, 125, 50, var(--tw-bg-opacity));
}

.active\:bg-teal-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 105, 92, var(--tw-bg-opacity));
}

.active\:bg-cyan-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 131, 143, var(--tw-bg-opacity));
}

.active\:bg-light-blue-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 119, 189, var(--tw-bg-opacity));
}

.active\:bg-blue-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 101, 192, var(--tw-bg-opacity));
}

.active\:bg-indigo-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 53, 147, var(--tw-bg-opacity));
}

.active\:bg-deep-purple-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(69, 39, 160, var(--tw-bg-opacity));
}

.active\:bg-purple-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(106, 27, 154, var(--tw-bg-opacity));
}

.active\:bg-pink-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(173, 20, 87, var(--tw-bg-opacity));
}

.active\:bg-red-800:active {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 40, 40, var(--tw-bg-opacity));
}

.active\:bg-blue-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(207, 216, 220, var(--tw-bg-opacity));
}

.active\:bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

.active\:bg-brown-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 204, 200, var(--tw-bg-opacity));
}

.active\:bg-deep-orange-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 204, 188, var(--tw-bg-opacity));
}

.active\:bg-orange-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 224, 178, var(--tw-bg-opacity));
}

.active\:bg-amber-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 236, 179, var(--tw-bg-opacity));
}

.active\:bg-yellow-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 249, 196, var(--tw-bg-opacity));
}

.active\:bg-lime-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(240, 244, 195, var(--tw-bg-opacity));
}

.active\:bg-light-green-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 237, 200, var(--tw-bg-opacity));
}

.active\:bg-green-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(200, 230, 201, var(--tw-bg-opacity));
}

.active\:bg-teal-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 223, 219, var(--tw-bg-opacity));
}

.active\:bg-cyan-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(178, 235, 242, var(--tw-bg-opacity));
}

.active\:bg-light-blue-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(179, 229, 252, var(--tw-bg-opacity));
}

.active\:bg-blue-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(187, 222, 251, var(--tw-bg-opacity));
}

.active\:bg-indigo-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(197, 202, 233, var(--tw-bg-opacity));
}

.active\:bg-deep-purple-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 196, 233, var(--tw-bg-opacity));
}

.active\:bg-purple-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 190, 231, var(--tw-bg-opacity));
}

.active\:bg-pink-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 187, 208, var(--tw-bg-opacity));
}

.active\:bg-red-100:active {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 205, 210, var(--tw-bg-opacity));
}

.active\:bg-gray-500:active {
  --tw-bg-opacity: 1;
  background-color: rgba(158, 158, 158, var(--tw-bg-opacity));
}

.active\:bg-opacity-40:active {
  --tw-bg-opacity: 0.4;
}

@media (min-width: 640px) {
  .sm\:left-auto {
    left: auto;
  }

  .sm\:w-full {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:mb-0 {
    margin-bottom: 0px;
  }

  .md\:w-9\/12 {
    width: 75%;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-4\/12 {
    width: 33.333333%;
  }

  .md\:w-5\/12 {
    width: 41.666667%;
  }

  .md\:w-8\/12 {
    width: 66.666667%;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pt-0 {
    padding-top: 0px;
  }
}

@media (min-width: 1024px) {
  .lg\:static {
    position: static;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-8\/12 {
    width: 66.666667%;
  }

  .lg\:w-60 {
    width: 15rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .lg\:text-left {
    text-align: left;
  }
}

/* //fonts */
@font-face {
  font-family: "typo_round";
  src: url("../Font/typo_round_bold_demo-webfont.woff2") format("woff2"),
    url("../Font/typo_round_bold_demo-webfont.woff") format("woff");
}

:root {
  --font-Montserrat: "Montserrat", sans-serif;
  --fancy-font: "Poppins", sans-serif;
}

body {
  font-family: var(--font-Montserrat), system-ui, -apple-system,
    /* Firefox supports this but not yet `system-ui` */
    "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.font-serif {
  font-family: var(--font-Montserrat);
}

.font-popins {
  font-family: "Poppins", sans-serif;
}

.font-typo {
  font-family: "typo_round";
}

/* ========================================================= */
/* my commin bundle */
li {
  background-image: none !important;
}

/* font family */
.underline {
  text-decoration: underline;
  line-height: 30px;
}

.text-capital {
  text-transform: capitalize;
}

.font-mont {
  font-family: var(--font-Montserrat);
}

.fancy-font {
  font-family: var(--fancy-font);
}

/* colors (text && bg) */
.text-osperb-blue {
  color: #1881bb;
}

.bg-osperb {
  background: #1881bb;
  color: #fff;
}

.bg-myGray {
  background-color: #f5f5f7;
}

.bg-whitesmoke-op {
  /* background-color: whitesmoke; */
  background-color: #0a0a0a;
}

.bg-whitesmoke {
  background-color: whitesmoke;
}

/* border-radius */
.border-r-30 {
  border-radius: 30px;
}

.w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

/*// layout //*/
/* height */
.height-80 {
  height: 80px;
}

.h-half-screen {
  height: 50vh;
}

.h-3\/4-screen {
  height: 75vh;
}

.h-2\.5\/4-screen {
  height: 62vh;
}

.min-h-half-screen {
  min-height: 50vh;
}

.plr-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* width */
/* flex*/
.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.w-15 {
  width: 15%;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

.w-50 {
  width: 50%;
}

.navBarUl {
  transition: all ease-in-out 0.6s;
}

.ulShow {
  -webkit-transform: translateY(0rem);
  transform: translateY(0rem);
}

.ulHidden {
  -webkit-transform: translateY(-5rem);
  transform: translateY(-5rem);
}

/* other styles */
.cursor-pointer {
  cursor: pointer !important;
}

.textarea-height textarea {
  height: 100px;
}

.blend {
  mix-blend-mode: difference;
}

/* display */
.d-none-bigger {
  display: none;
}

.d-none-smaller {
  display: block;
}

.object-cover {
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .d-none-bigger {
    display: block;
  }

  .d-none-smaller {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .sm\:p-10 {
    padding: 1.5rem;
  }

  .sm\:w-9 {
    width: 90%;
  }

  .sm\:w-none {
    display: none;
  }

  .sm\:bg-glass {
    background: rgba(255, 255, 255, 0.45);
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37); */
    /* backdrop-filter: blur(4px); */
    /* -webkit-backdrop-filter: blur(4px); */
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    margin-left: inherit;
  }

  .ulHidden {
    -webkit-transform: translateY(-20rem);
    transform: translateY(-20rem);
  }
}

/* ========================================================= */
/*///////// custome css ///////////*/
/* .h-w-full {
  height: 500px;
  width: 500px;
} */
.MSI_show_element {
  display: none;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.gallery-item a {
  height: 100%;
}

.bottom_card.move {
  bottom: -100px;
}

.bottom_card {
  position: absolute;
  width: 100%;
  /* background: #d8d8d8; */
  padding: 15px;
  /* transform: translateY(100px); */
  transition: all ease-in-out 0.3s;
  background: rgb(251 251 253 / 25%);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.gallery-item:hover .bottom_card {
  /* transform: translateY(0px); */
  bottom: 0px;
}

.custom-flex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.box_sm {
  -webkit-clip-path: polygon(25% 0%,
      75% 0%,
      100% 50%,
      75% 100%,
      25% 100%,
      0% 50%);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  width: 300px;
  height: 300px;
  background-color: #f2f2f2;
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
  margin: 0 1rem;
}

.box_sm.hidden_bg {
  background: #0a0a0a;
}

.box_sm img {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.gray-hover {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transition: all ease-in-out 0.3s;
}

.gray-hover:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.bg-landing-background {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)),
    url(../img/osperb/team.svg); */
  background-image: linear-gradient(rgba(255, 255, 255, 0.11), rgba(0, 0, 0, 2)),
    url(../img/osperb/illustration/team.svg);
}

.underline-hover {
  text-decoration: none;
  letter-spacing: 0.15em;
  display: inline-block;
  position: relative;
  line-height: 27px;
}

.underline-hover:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  background: #cd7c3c;
  transition: width 0.3s ease-in-out 0s, left 0.3s ease-in-out 0s;
  width: 0;
}

.underline-hover:hover:after {
  width: 100%;
}

.underline-hover.full:after {
  width: 100%;
}

.sp-underline-blue {
  /* border-bottom: 4px solid #1881bb; */
  line-height: 35px;
  position: relative;
}

.sp-underline-blue:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  position: absolute;
  background: #1881bb;
  transition: width 0.3s ease-in-out 0s, left 0.3s ease-in-out 0s;
  width: 100%;
}

.sp-underline-blue:hover:after {
  width: 200%;
}

.navHide {
  /* opacity: 0; */
  /* visibility: hidden; */
  -webkit-transform: translateY(-90px);
  transform: translateY(-90px);
}

.navShow {
  /* opacity: 1; */
  /* visibility: visible; */
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.nav-span span {
  height: 100%;
}

.fixed_menu {
  font-weight: 700;
  position: fixed;
  width: 100vh;
  text-align: center;
  mix-blend-mode: difference;
  /* left: 0; */
  z-index: 2;
}

.fixed_menu h1 {
  color: white;
}

.video-container {
  width: 100%;
  margin-top: -2rem;
  height: 60%;
  transition: all ease-in-out 02s;
  margin-left: -70px;
}

.video-tag {
  width: 100%;
  box-shadow: 20px 20px 60px 0px #d9d9d9a6, -20px -20px 20px #ffffff;
  border-radius: 1.3rem;
}

.video-container:hover {
  -webkit-transform: translate(-10px, -10px);
  transform: translate(-10px, -10px);
}

.video-tag2 {
  width: 100%;
  border-radius: 1.3rem;
}

.footer_logo {
  margin-left: -25px;
}

.show-card-anim-bf {
  -webkit-transform: translate(0, -10rem);
  transform: translate(0, -10rem);
  opacity: 0;
  visibility: hidden;
}

.show-card-anim-af {
  -webkit-transform: translate(0, 0rem);
  transform: translate(0, 0rem);
  opacity: 1;
  visibility: visible;
}

.isoTopContainer {
  /* margin: auto; */
  /* width: 80%; */
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.img-400 {
  height: 100% !important;
  object-fit: cover;
}

.isoTopContainer::-webkit-scrollbar {
  display: none;
}

.custome-scroll {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 10px;
  background-color: #fbfbfd;
}

.custome-scroll-div {
  width: 500px;
  height: 500px;
  position: relative;
  background-color: #f2f2f2;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
}

.label_card {
  position: absolute;
  bottom: -20rem;
  border-radius: 0;
  box-shadow: none;
  transition: all 0.45s ease-in-out;
  background: rgb(251 251 253 / 25%);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.react__arrowPrev {
  bottom: 0;
  position: absolute;
  font-size: 2.5rem;
  right: 50px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

.react__arrowNext {
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  font-size: 2.5rem;
}

.custome-scroll-div:hover .label_card {
  bottom: 0rem;
}

.view_more_card {
  width: 250px;
  height: 500px;
}

.custome_scroll_bar {
  overflow-x: scroll;
}

.slick-slide {
  padding: 0.5rem;
}

.about-px-4 {
  padding-right: 4rem;
}

.hover.blue:hover {
  background-color: rgb(0 168 244);
  color: #fff !important;
}

@media screen and (max-width: 992px) {
  .sm\:d-none {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .sm\:h-400 {
    height: 400px;
  }

  .sm\:object-position {
    object-position: left;
  }

  .sm\:h-full {
    height: 100%;
  }

  .sm\:object-cover {
    object-fit: cover;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:bg-white {
    background-color: #fff;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0 !important;
  }

  .isoTopContainer {
    margin: auto;
    width: 100%;
  }

  .sm\:pt-28 {
    padding-top: 7rem;
  }

  .sm\:flex-invert {
    flex-direction: column-reverse;
  }

  .custome-scroll-div {
    width: 350px;
    height: 350px;
  }

  .view_more_card {
    width: 250px;
    height: 350px;
  }

  .md-px-auto {
    padding: 5rem 2rem;
  }

  .bg-md-gray {
    background-color: #f5f5f5;
  }

  .innerdiv-px {
    padding: 5rem 0;
  }

  .about-px-4 {
    padding-right: 0;
  }

  .sm\:flex-invert {
    flex-direction: column-reverse;
  }

  .label_card {
    bottom: 0rem;
    background: rgb(189 186 187 / 58%);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  }

  .sm\:text-4xl {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .sm\:text-md {
    font-size: 0.975rem;
    line-height: 1.5rem;
  }
}

@media screen and (max-width: 992px) {

  .footer_logo,
  .footer_icon {
    margin: auto;
  }

  .footer_icon {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 580px) {
  .sm\:initial {
    position: initial !important;
  }

  .video-container {
    margin-left: -35px;
  }

  .sm\:w-90 {
    width: 90% !important;
  }

  .sm\:mt-0 {
    margin-top: 0 !important;
  }

  .sm\:mb-2 {
    margin-bottom: 2rem;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }
}

.last\:mr-0:last-child {
  margin-right: 0px;
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 640px) {
  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:mb-2 {
    margin-bottom: 0.5rem;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }
}

@media (min-width: 768px) {
  .md\:mb-0 {
    margin-bottom: 0px;
  }

  .md\:w-4\/12 {
    width: 33.333333%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-7\/12 {
    width: 58.333333%;
  }

  .md\:w-9\/12 {
    width: 75%;
  }

  .md\:w-5\/12 {
    width: 41.666667%;
  }

  .md\:w-10\/12 {
    width: 83.333333%;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-between {
    justify-content: space-between;
  }
}

@media (min-width: 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-5\/12 {
    width: 41.666667%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-8\/12 {
    width: 66.666667%;
  }

  .lg\:w-10\/12 {
    width: 83.333333%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-20 {
    padding: 5rem;
  }

  .lg\:pt-0 {
    padding-top: 0px;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:text-left {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .isoTopContainer {
    margin: auto;
    width: 100%;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .sm\:flex-invert {
    flex-direction: column-reverse;
  }

  .custome-scroll-div {
    width: 350px;
    height: 350px;
  }

  .view_more_card {
    width: 250px;
    height: 350px;
  }

  .md-px-auto {
    padding: 5rem 2rem;
  }

  .bg-md-gray {
    background-color: #f5f5f5;
  }

  .innerdiv-px {
    padding: 5rem 0;
  }

  .about-px-4 {
    padding-right: 0;
  }

  .sm\:flex-invert {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 992px) {

  .footer_logo,
  .footer_icon {
    margin: auto;
  }

  .footer_icon {
    width: fit-content;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 580px) {
  .sm\:initial {
    position: initial !important;
  }

  .video-container {
    margin-left: -35px;
  }

  .sm\:w-90 {
    width: 90% !important;
  }

  .sm\:mt-0 {
    margin-top: 0 !important;
  }

  .sm\:mb-2 {
    margin-bottom: 2rem;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }
}

.points li {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  color: 'blueGray';
  list-style-type: circle;
  margin-left:15px;
}